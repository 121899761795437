import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="flex flex-col min-h-screen p-16">
        {/* header */}
        <div className="flex justify-between">
          <div className="flex">
            <a href="https://www.ans.ventures/">
              <img src="/logo.png" alt="" className="h-10" />
            </a>
          </div>
          <div className="flex">
            <a
              href="https://ans.ventures"
              className="uppercase button button--primary"
            >
              Contact
            </a>
          </div>
        </div>
        <div className="max-w-screen-lg mx-auto">
          {/* title */}
          <div className="flex flex-col justify-center items-center">
            <h1 className="title">PORTFOLIO</h1>
            <p className="text text-center">
              We are proud of the values we are building. Blockchain, NFT,
              GameFi and their actual achievements will break out even stronger
              in the future. Check out the projects that we have successfully
              invested in and mentored below.
            </p>
          </div>
          {/* portfolio */}
          <div className="grid grid-cols-1 gap-10">
            <div className="flex space-y-2 bg-white rounded p-10 space-x-10">
              <div className="flex justify-center items-center w-2/12">
                <a href="https://cryptozerofi.io/">
                  <img
                    src="https://test.cryptozerofi.io/static/media/logo.9bc4019f.png"
                    alt="Portfolio logo"
                    className="h-20 transition duration-800 grayscale-0 hover:grayscale"
                  />
                </a>
              </div>
              <div className="flex flex-col w-10/12">
                <div className="flex flex-col pb-4">
                  <div className="flex space-x-2">
                    <h2 className="text-2xl">CryptoZeroFi</h2>
                    <p className="bg-green-400 rounded-lg px-2 py-1 text-white">
                      SEED
                    </p>
                  </div>
                  <p className="text-gray-500">
                    ZeroFi is a combat role-playing NFT game built on the
                    Binance Smart Chain with Zero Fee goal and Metaverse
                    orientation.
                  </p>
                </div>
                <div className="flex space-x-10">
                  <div className="flex flex-col">
                    <p className="text-xl text-gray-500">Total invest</p>
                    <p className="text-2xl">TBD</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-xl text-gray-500">All Time High</p>
                    <p className="text-2xl">TBD</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="flex justify-between px-20 py-10 text-white">
        <div className="flex space-x-4">
          <a href="https://t.me/ANSVentures">
            <img src="/images/telegram.svg" alt="" className="h-5 invert" />
          </a>
          <a href="https://twitter.com/ANSVentures">
            <img src="/images/twitter.svg" alt="" className="h-5 invert" />
          </a>
        </div>
        <p className="footer">© 2022 ANS Ventures</p>
      </div>
    </div>
  );
}

export default App;
